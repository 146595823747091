import React, { useState, useEffect } from 'react';
import { Typography, Form, Input, Select, Button, Row, Col, message, Switch } from 'antd';
import axios from 'axios';
import { useGetIdentity } from "@refinedev/core";
import config from "config/config";
const API_BASE_URL = config.API_BASE_URL;

const { Title } = Typography;
const { Option } = Select;

interface FormData {
    widget_configs: {
        [key: string]: any;
    },
    profile_configs: {
        [key: string]: any;
    },
    feature_configs: {
        [key: string]: any;
    }
}

export const WidgetCustomize = () => {
    const { data: user } = useGetIdentity<{
        user_websites: {
            url: string,
            selected: string
            widget_status: string,
            btn_color: string,
            primary_color: string,
            secondary_color: string,
            headings_color: string,
            text_color: string,
            wid_size: string,
            wid_location: string,
            vision_impaired_profile: string,
            blind_profile: string,
            dyslexic_profile: string,
            cognitive_impaired_profile: string,
            adhd_profile: string,
            motor_impaired_profile: string,
            screen_reader_status: string,
            voice_nav_status: string,
            dark_contrast_status: string,
            high_contrast_status: string,
            low_contrast_status: string,
            monochrome_status: string,
            high_saturation_status: string,
            low_saturation_status: string,
            font_size_status: string,
            dyslexia_font_status: string,
            left_align_status: string,
            right_align_status: string,
            center_align_status: string,
            justify_align_status: string,
            line_height_status: string,
            text_spacing_status: string,
            highlight_links_status: string,
            highlight_headings_status: string,
            pause_animations_status: string,
            hide_images_status: string,
            white_cursor_status: string,
            black_cursor_status: string,
            keyboard_nav_status: string,
            highlight_hover_status: string,
            reading_mask_status: string,
            reading_guide_status: string
        }[];
        mutate: () => void;
    }>();

    const [formData, setFormData] = useState<FormData>({
        widget_configs: {},
        profile_configs: {},
        feature_configs: {}
    });

    useEffect(() => {
        const defaultWebConfigs = user?.user_websites?.find((website) => website.selected === 'true');
        if (defaultWebConfigs) {
            const { widget_status, btn_color, primary_color, secondary_color, headings_color, text_color, wid_size, wid_location,
                vision_impaired_profile, blind_profile, cognitive_impaired_profile, dyslexic_profile, adhd_profile,
                motor_impaired_profile, screen_reader_status, voice_nav_status, dark_contrast_status, high_contrast_status,
                low_contrast_status, monochrome_status, high_saturation_status, low_saturation_status, font_size_status,
                dyslexia_font_status, left_align_status, right_align_status, center_align_status, justify_align_status,
                line_height_status, text_spacing_status, highlight_links_status, highlight_headings_status, pause_animations_status,
                hide_images_status, white_cursor_status, black_cursor_status, keyboard_nav_status, highlight_hover_status,
                reading_mask_status, reading_guide_status
            } = defaultWebConfigs;

            setFormData({
                widget_configs: {
                    wid_status: widget_status,
                    btn_color: btn_color,
                    primary_color: primary_color,
                    secondary_color: secondary_color,
                    headings_color: headings_color,
                    text_color: text_color,
                    wid_size: wid_size,
                    wid_location: wid_location,
                },
                profile_configs: {
                    vision_impaired_profile: vision_impaired_profile,
                    blind_profile: blind_profile,
                    cognitive_impaired_profile: cognitive_impaired_profile,
                    dyslexic_profile: dyslexic_profile,
                    adhd_profile: adhd_profile,
                    motor_impaired_profile: motor_impaired_profile
                },
                feature_configs: {
                    screen_reader_status: screen_reader_status,
                    voice_nav_status: voice_nav_status,
                    dark_contrast_status: dark_contrast_status,
                    high_contrast_status: high_contrast_status,
                    low_contrast_status: low_contrast_status,
                    monochrome_status: monochrome_status,
                    high_saturation_status: high_saturation_status,
                    low_saturation_status: low_saturation_status,
                    font_size_status: font_size_status,
                    dyslexia_font_status: dyslexia_font_status,
                    left_align_status: left_align_status,
                    right_align_status: right_align_status,
                    center_align_status: center_align_status,
                    justify_align_status: justify_align_status,
                    line_height_status: line_height_status,
                    text_spacing_status: text_spacing_status,
                    highlight_links_status: highlight_links_status,
                    highlight_headings_status: highlight_headings_status,
                    pause_animations_status: pause_animations_status,
                    hide_images_status: hide_images_status,
                    white_cursor_status: white_cursor_status,
                    black_cursor_status: black_cursor_status,
                    keyboard_nav_status: keyboard_nav_status,
                    highlight_hover_status: highlight_hover_status,
                    reading_mask_status: reading_mask_status,
                    reading_guide_status: reading_guide_status
                }
            });
        }
    }, [user]);

    const handleChange = (key: string, value: any) => {
        setFormData(formData => {
            const newProfileConfigs = { ...formData.profile_configs };
            let newFeatureConfigs = { ...formData.feature_configs };
            const newWidgetConfigs = { ...formData.widget_configs };

            const profileFeatureMapping: Record<string, string[]> = {
                vision_impaired_profile: ['high_saturation_status', 'white_cursor_status'],
                blind_profile: ['screen_reader_status', 'voice_nav_status'],
                dyslexic_profile: ['dyslexia_font_status', 'pause_animations_status'],
                cognitive_impaired_profile: ['pause_animations_status', 'reading_guide_status'],
                adhd_profile: ['high_saturation_status', 'pause_animations_status', 'reading_mask_status'],
                motor_impaired_profile: ['pause_animations_status', 'white_cursor_status', 'keyboard_nav_status']
            };

            // Handle profile configurations
            if (Object.keys(profileFeatureMapping).includes(key)) {
                // Deactivate other profiles and set the selected profile to active
                (Object.keys(newProfileConfigs) as Array<keyof typeof newProfileConfigs>).forEach(profileKey => {
                    newProfileConfigs[profileKey] = 'inactive';
                    // Deactivate features associated with the profile
                    profileFeatureMapping[profileKey].forEach(featureKey => {
                        newFeatureConfigs[featureKey] = 'inactive';
                    });
                });
                newProfileConfigs[key as keyof typeof newProfileConfigs] = value;

                // Activate features associated with the profile if the profile is activated
                if (value === 'active') {
                    profileFeatureMapping[key].forEach(featureKey => {
                        newFeatureConfigs[featureKey] = 'active';
                    });
                }
            }

            // Integrate feature exclusivity logic
            const handleExclusiveFeatures = (featureKey: string, isActive: boolean) => {
                if (['dark_contrast_status', 'high_contrast_status', 'low_contrast_status'].includes(featureKey)) {
                    newFeatureConfigs.dark_contrast_status = 'inactive';
                    newFeatureConfigs.high_contrast_status = 'inactive';
                    newFeatureConfigs.low_contrast_status = 'inactive';
                }

                if (['high_saturation_status', 'low_saturation_status'].includes(featureKey)) {
                    newFeatureConfigs.high_saturation_status = 'inactive';
                    newFeatureConfigs.low_saturation_status = 'inactive';
                }

                if (['left_align_status', 'right_align_status', 'center_align_status', 'justify_align_status'].includes(featureKey)) {
                    newFeatureConfigs.left_align_status = 'inactive';
                    newFeatureConfigs.right_align_status = 'inactive';
                    newFeatureConfigs.center_align_status = 'inactive';
                    newFeatureConfigs.justify_align_status = 'inactive';
                }

                if (['white_cursor_status', 'black_cursor_status'].includes(featureKey)) {
                    newFeatureConfigs.white_cursor_status = 'inactive';
                    newFeatureConfigs.black_cursor_status = 'inactive';
                }

                // Set the specific feature to the new value
                newFeatureConfigs[featureKey] = isActive ? 'active' : 'inactive';
            };

            // Apply feature exclusivity logic for activated profile features
            if (Object.keys(profileFeatureMapping).includes(key) && value === 'active') {
                profileFeatureMapping[key].forEach(featureKey => {
                    handleExclusiveFeatures(featureKey, true);
                });
            }

            // Handle individual feature updates
            if (['dark_contrast_status', 'high_contrast_status', 'low_contrast_status', 'high_saturation_status', 'low_saturation_status',
                'left_align_status', 'right_align_status', 'center_align_status', 'justify_align_status', 'white_cursor_status',
                'black_cursor_status', 'highlight_links_status', 'highlight_headings_status', 'pause_animations_status',
                'hide_images_status', 'keyboard_nav_status', 'highlight_hover_status', 'reading_mask_status', 'reading_guide_status',
                'monochrome_status', 'font_size_status', 'dyslexia_font_status', 'line_height_status', 'text_spacing_status',
                'screen_reader_status', 'voice_nav_status'].includes(key)) {
                handleExclusiveFeatures(key, value === 'active');
            }

            // Handle widget configurations
            if (['wid_status', 'btn_color', 'primary_color', 'secondary_color', 'headings_color', 'text_color', 'wid_size', 'wid_location'].includes(key)) {
                newWidgetConfigs[key] = value;
            }

            return {
                ...formData,
                profile_configs: newProfileConfigs,
                feature_configs: newFeatureConfigs,
                widget_configs: newWidgetConfigs
            };
        });
    };

    const handleReset = () => {
        setFormData({
            widget_configs: {
                wid_status: 'active',
                btn_color: '#018e86',
                primary_color: '#018e86',
                secondary_color: '#d0eeed',
                headings_color: '#fff',
                text_color: '#000',
                wid_size: 'medium',
                wid_location: 'bottomRight',
            },
            profile_configs: {
                vision_impaired_profile: "inactive",
                blind_profile: "inactive",
                cognitive_impaired_profile: "inactive",
                dyslexic_profile: "inactive",
                adhd_profile: "inactive",
                motor_impaired_profile: "inactive",
            },
            feature_configs: {
                screen_reader_status: "inactive",
                voice_nav_status: "inactive",
                dark_contrast_status: "inactive",
                high_contrast_status: "inactive",
                low_contrast_status: "inactive",
                monochrome_status: "inactive",
                high_saturation_status: "inactive",
                low_saturation_status: "inactive",
                font_size_status: "inactive",
                dyslexia_font_status: "inactive",
                left_align_status: "inactive",
                right_align_status: "inactive",
                center_align_status: "inactive",
                justify_align_status: "inactive",
                line_height_status: "inactive",
                text_spacing_status: "inactive",
                highlight_links_status: "inactive",
                highlight_headings_status: "inactive",
                pause_animations_status: "inactive",
                hide_images_status: "inactive",
                white_cursor_status: "inactive",
                black_cursor_status: "inactive",
                keyboard_nav_status: "inactive",
                highlight_hover_status: "inactive",
                reading_mask_status: "inactive",
                reading_guide_status: "inactive",
            }
        });
        // submit default values to db
        handleSubmit();
    };



    const handleSubmit = async () => {
        try {
            const token = localStorage.getItem("wafy-access-token");
            const selected_website = user?.user_websites.find((website) => website.selected === 'true')?.url;
            await axios.put(`${API_BASE_URL}/user/api/v1/configs`, { widget_configs_data: formData, website: selected_website }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            message.success("Configs updated successfully!");
        } catch (error) {
            console.error("Error updating configurations:", error);
            message.error("Something went wrong!");
        }
    }

    return (
        <>
            <div style={{ marginBottom: '20px' }}>
                <Title level={4}>Widget Customization</Title>
                <div style={{ marginTop: '20px', padding: '20px', borderRadius: '15px', backgroundColor: '#fcfcfc' }}>
                    <Form layout="vertical" onFinish={handleSubmit}>
                        <Row gutter={[16, 8]}>
                            <Col xs={24} sm={6}>
                                <div style={{ marginBottom: '8px' }}>Widget Status</div>
                                <Form.Item style={{ marginBottom: 0, borderRadius: 8, border: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }}>
                                    <Row align="middle" justify="space-between">
                                        <Col>
                                            Enable Widget
                                        </Col>
                                        <Col>
                                            <Switch
                                                checked={formData?.widget_configs?.wid_status === 'active'}
                                                onChange={(checked) => handleChange('wid_status', checked ? 'active' : 'inactive')}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Form.Item label="Change Button Color">
                                    <Input
                                        type="color"
                                        onChange={(e) => handleChange('btn_color', e.target.value)}
                                        value={formData?.widget_configs?.btn_color}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Form.Item label="Widget Primary Color">
                                    <Input
                                        type="color"
                                        onChange={(e) => handleChange('primary_color', e.target.value)}
                                        value={formData?.widget_configs?.primary_color}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Form.Item label="Widget Secondary Color">
                                    <Input
                                        type="color"
                                        onChange={(e) => handleChange('secondary_color', e.target.value)}
                                        value={formData?.widget_configs?.secondary_color}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[16, 8]}>
                            <Col xs={24} sm={6}>
                                <Form.Item label="Heading Color">
                                    <Input
                                        type="color"
                                        onChange={(e) => handleChange('headings_color', e.target.value)}
                                        value={formData?.widget_configs?.headings_color}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Form.Item label="Text Color">
                                    <Input
                                        type="color"
                                        onChange={(e) => handleChange('text_color', e.target.value)}
                                        value={formData?.widget_configs?.text_color}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Form.Item label="Change Widget Size">
                                    <Select
                                        placeholder="Select"
                                        onChange={(value) => handleChange('wid_size', value)}
                                        value={formData?.widget_configs?.wid_size}
                                    >
                                        <Option value='small'>Small</Option>
                                        <Option value='medium'>Medium</Option>
                                        <Option value='large'>Large</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Form.Item label="Change Widget Position">
                                    <Select
                                        placeholder="Select"
                                        onChange={(value) => handleChange('wid_location', value)}
                                        value={formData?.widget_configs?.wid_location}
                                    >
                                        <Option value='topRight'>Top Right</Option>
                                        <Option value='topLeft'>Top Left</Option>
                                        <Option value='bottomRight'>Bottom Right</Option>
                                        <Option value='bottomLeft'>Bottom Left</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item style={{ marginTop: '10px' }}>
                            <Button type="primary" htmlType="submit">
                                Submit Customizations
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>

            <div>
                <Title level={4}>Profile Customization</Title>
                <div style={{ marginTop: '20px', padding: '20px', borderRadius: '15px', backgroundColor: '#fcfcfc', marginBottom: '20px' }}>
                    <Form layout="vertical" onFinish={handleSubmit}>
                        <Row gutter={[16, 8]} style={{ marginBottom: '20px' }}>
                            <Col xs={24} sm={8}>
                                <Form.Item style={{ marginBottom: 0, borderRadius: 8, border: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }}>
                                    <Row align="middle" justify="space-between">
                                        <Col>
                                            Visual Impaired Profile
                                        </Col>
                                        <Col>
                                            <Switch
                                                checked={formData?.profile_configs?.vision_impaired_profile === 'active'}
                                                onChange={(checked) => handleChange('vision_impaired_profile', checked ? 'active' : 'inactive')}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8}>
                                <Form.Item style={{ marginBottom: 0, borderRadius: 8, border: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }}>
                                    <Row align="middle" justify="space-between">
                                        <Col>
                                            Blind Profile
                                        </Col>
                                        <Col>
                                            <Switch
                                                checked={formData?.profile_configs?.blind_profile === 'active'}
                                                onChange={(checked) => handleChange('blind_profile', checked ? 'active' : 'inactive')}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8}>
                                <Form.Item style={{ marginBottom: 0, borderRadius: 8, border: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }}>
                                    <Row align="middle" justify="space-between">
                                        <Col>
                                            Dyslexic Profile
                                        </Col>
                                        <Col>
                                            <Switch
                                                checked={formData?.profile_configs?.dyslexic_profile === 'active'}
                                                onChange={(checked) => handleChange('dyslexic_profile', checked ? 'active' : 'inactive')}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[16, 8]} style={{ marginBottom: '20px' }}>
                            <Col xs={24} sm={8}>
                                <Form.Item style={{ marginBottom: 0, borderRadius: 8, border: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }}>
                                    <Row align="middle" justify="space-between">
                                        <Col>
                                            Cognitive Impaired Profile
                                        </Col>
                                        <Col>
                                            <Switch
                                                checked={formData?.profile_configs?.cognitive_impaired_profile === 'active'}
                                                onChange={(checked) => handleChange('cognitive_impaired_profile', checked ? 'active' : 'inactive')}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8}>
                                <Form.Item style={{ marginBottom: 0, borderRadius: 8, border: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }}>
                                    <Row align="middle" justify="space-between">
                                        <Col>
                                            ADHD Profile
                                        </Col>
                                        <Col>
                                            <Switch
                                                checked={formData?.profile_configs?.adhd_profile === 'active'}
                                                onChange={(checked) => handleChange('adhd_profile', checked ? 'active' : 'inactive')}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8}>
                                <Form.Item style={{ marginBottom: 0, borderRadius: 8, border: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }}>
                                    <Row align="middle" justify="space-between">
                                        <Col>
                                            Motor Impaired Profile
                                        </Col>
                                        <Col>
                                            <Switch
                                                checked={formData?.profile_configs?.motor_impaired_profile === 'active'}
                                                onChange={(checked) => handleChange('motor_impaired_profile', checked ? 'active' : 'inactive')}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item style={{ marginTop: '35px' }}>
                            <Button type="primary" htmlType="submit">
                                Submit Customizations
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>

            <div>
                <Title level={4}>Feature Customization</Title>
                <div style={{ marginTop: '20px', padding: '20px', borderRadius: '15px', backgroundColor: '#fcfcfc', marginBottom: '20px' }}>
                    <Form layout="vertical" onFinish={handleSubmit}>
                        <Row gutter={[16, 8]} style={{ marginBottom: '20px' }}>
                            <Col xs={24} sm={6}>
                                <Form.Item style={{ marginBottom: 0, borderRadius: 8, border: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }}>
                                    <Row align="middle" justify="space-between">
                                        <Col>
                                            Screen Reader
                                        </Col>
                                        <Col>
                                            <Switch
                                                checked={formData?.feature_configs?.screen_reader_status === 'active'}
                                                onChange={(checked) => handleChange('screen_reader_status', checked ? 'active' : 'inactive')}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Form.Item style={{ marginBottom: 0, borderRadius: 8, border: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }}>
                                    <Row align="middle" justify="space-between">
                                        <Col>
                                            Voice Navigation
                                        </Col>
                                        <Col>
                                            <Switch
                                                checked={formData?.feature_configs?.voice_nav_status === 'active'}
                                                onChange={(checked) => handleChange('voice_nav_status', checked ? 'active' : 'inactive')}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Form.Item style={{ marginBottom: 0, borderRadius: 8, border: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }}>
                                    <Row align="middle" justify="space-between">
                                        <Col>
                                            Dark Contrast
                                        </Col>
                                        <Col>
                                            <Switch
                                                checked={formData?.feature_configs?.dark_contrast_status === 'active'}
                                                onChange={(checked) => handleChange('dark_contrast_status', checked ? 'active' : 'inactive')}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Form.Item style={{ marginBottom: 0, borderRadius: 8, border: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }}>
                                    <Row align="middle" justify="space-between">
                                        <Col>
                                            High Contrast
                                        </Col>
                                        <Col>
                                            <Switch
                                                checked={formData?.feature_configs?.high_contrast_status === 'active'}
                                                onChange={(checked) => handleChange('high_contrast_status', checked ? 'active' : 'inactive')}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[16, 8]} style={{ marginBottom: '20px' }}>
                            <Col xs={24} sm={6}>
                                <Form.Item style={{ marginBottom: 0, borderRadius: 8, border: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }}>
                                    <Row align="middle" justify="space-between">
                                        <Col>
                                            Low Contrast
                                        </Col>
                                        <Col>
                                            <Switch
                                                checked={formData?.feature_configs?.low_contrast_status === 'active'}
                                                onChange={(checked) => handleChange('low_contrast_status', checked ? 'active' : 'inactive')}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Form.Item style={{ marginBottom: 0, borderRadius: 8, border: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }}>
                                    <Row align="middle" justify="space-between">
                                        <Col>
                                            MonoChrome
                                        </Col>
                                        <Col>
                                            <Switch
                                                checked={formData?.feature_configs?.monochrome_status === 'active'}
                                                onChange={(checked) => handleChange('monochrome_status', checked ? 'active' : 'inactive')}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Form.Item style={{ marginBottom: 0, borderRadius: 8, border: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }}>
                                    <Row align="middle" justify="space-between">
                                        <Col>
                                            High Saturation
                                        </Col>
                                        <Col>
                                            <Switch
                                                checked={formData?.feature_configs?.high_saturation_status === 'active'}
                                                onChange={(checked) => handleChange('high_saturation_status', checked ? 'active' : 'inactive')}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Form.Item style={{ marginBottom: 0, borderRadius: 8, border: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }}>
                                    <Row align="middle" justify="space-between">
                                        <Col>
                                            Low Saturation
                                        </Col>
                                        <Col>
                                            <Switch
                                                checked={formData?.feature_configs?.low_saturation_status === 'active'}
                                                onChange={(checked) => handleChange('low_saturation_status', checked ? 'active' : 'inactive')}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[16, 8]} style={{ marginBottom: '20px' }}>
                            <Col xs={24} sm={6}>
                                <Form.Item style={{ marginBottom: 0, borderRadius: 8, border: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }}>
                                    <Row align="middle" justify="space-between">
                                        <Col>
                                            Increase Font Size
                                        </Col>
                                        <Col>
                                            <Switch
                                                checked={formData?.feature_configs?.font_size_status === 'active'}
                                                onChange={(checked) => handleChange('font_size_status', checked ? 'active' : 'inactive')}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Form.Item style={{ marginBottom: 0, borderRadius: 8, border: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }}>
                                    <Row align="middle" justify="space-between">
                                        <Col>
                                            Dyslexia Friendly
                                        </Col>
                                        <Col>
                                            <Switch
                                                checked={formData?.feature_configs?.dyslexia_font_status === 'active'}
                                                onChange={(checked) => handleChange('dyslexia_font_status', checked ? 'active' : 'inactive')}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Form.Item style={{ marginBottom: 0, borderRadius: 8, border: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }}>
                                    <Row align="middle" justify="space-between">
                                        <Col>
                                            Left Align
                                        </Col>
                                        <Col>
                                            <Switch
                                                checked={formData?.feature_configs?.left_align_status === 'active'}
                                                onChange={(checked) => handleChange('left_align_status', checked ? 'active' : 'inactive')}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Form.Item style={{ marginBottom: 0, borderRadius: 8, border: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }}>
                                    <Row align="middle" justify="space-between">
                                        <Col>
                                            Right Align
                                        </Col>
                                        <Col>
                                            <Switch
                                                checked={formData?.feature_configs?.right_align_status === 'active'}
                                                onChange={(checked) => handleChange('right_align_status', checked ? 'active' : 'inactive')}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[16, 8]} style={{ marginBottom: '20px' }}>
                            <Col xs={24} sm={6}>
                                <Form.Item style={{ marginBottom: 0, borderRadius: 8, border: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }}>
                                    <Row align="middle" justify="space-between">
                                        <Col>
                                            Center Align
                                        </Col>
                                        <Col>
                                            <Switch
                                                checked={formData?.feature_configs?.center_align_status === 'active'}
                                                onChange={(checked) => handleChange('center_align_status', checked ? 'active' : 'inactive')}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Form.Item style={{ marginBottom: 0, borderRadius: 8, border: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }}>
                                    <Row align="middle" justify="space-between">
                                        <Col>
                                            Justify Align
                                        </Col>
                                        <Col>
                                            <Switch
                                                checked={formData?.feature_configs?.justify_align_status === 'active'}
                                                onChange={(checked) => handleChange('justify_align_status', checked ? 'active' : 'inactive')}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Form.Item style={{ marginBottom: 0, borderRadius: 8, border: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }}>
                                    <Row align="middle" justify="space-between">
                                        <Col>
                                            Increase Line Height
                                        </Col>
                                        <Col>
                                            <Switch
                                                checked={formData?.feature_configs?.line_height_status === 'active'}
                                                onChange={(checked) => handleChange('line_height_status', checked ? 'active' : 'inactive')}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Form.Item style={{ marginBottom: 0, borderRadius: 8, border: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }}>
                                    <Row align="middle" justify="space-between">
                                        <Col>
                                            Increase Text Spacing
                                        </Col>
                                        <Col>
                                            <Switch
                                                checked={formData?.feature_configs?.text_spacing_status === 'active'}
                                                onChange={(checked) => handleChange('text_spacing_status', checked ? 'active' : 'inactive')}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[16, 8]} style={{ marginBottom: '20px' }}>
                            <Col xs={24} sm={6}>
                                <Form.Item style={{ marginBottom: 0, borderRadius: 8, border: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }}>
                                    <Row align="middle" justify="space-between">
                                        <Col>
                                            Highlight Links
                                        </Col>
                                        <Col>
                                            <Switch
                                                checked={formData?.feature_configs?.highlight_links_status === 'active'}
                                                onChange={(checked) => handleChange('highlight_links_status', checked ? 'active' : 'inactive')}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Form.Item style={{ marginBottom: 0, borderRadius: 8, border: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }}>
                                    <Row align="middle" justify="space-between">
                                        <Col>
                                            Highlight Headings
                                        </Col>
                                        <Col>
                                            <Switch
                                                checked={formData?.feature_configs?.highlight_headings_status === 'active'}
                                                onChange={(checked) => handleChange('highlight_headings_status', checked ? 'active' : 'inactive')}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Form.Item style={{ marginBottom: 0, borderRadius: 8, border: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }}>
                                    <Row align="middle" justify="space-between">
                                        <Col>
                                            Pause Animations
                                        </Col>
                                        <Col>
                                            <Switch
                                                checked={formData?.feature_configs?.pause_animations_status === 'active'}
                                                onChange={(checked) => handleChange('pause_animations_status', checked ? 'active' : 'inactive')}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Form.Item style={{ marginBottom: 0, borderRadius: 8, border: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }}>
                                    <Row align="middle" justify="space-between">
                                        <Col>
                                            Hide Images
                                        </Col>
                                        <Col>
                                            <Switch
                                                checked={formData?.feature_configs?.hide_images_status === 'active'}
                                                onChange={(checked) => handleChange('hide_images_status', checked ? 'active' : 'inactive')}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[16, 8]} style={{ marginBottom: '20px' }}>
                            <Col xs={24} sm={6}>
                                <Form.Item style={{ marginBottom: 0, borderRadius: 8, border: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }}>
                                    <Row align="middle" justify="space-between">
                                        <Col>
                                            Big White Cursor
                                        </Col>
                                        <Col>
                                            <Switch
                                                checked={formData?.feature_configs?.white_cursor_status === 'active'}
                                                onChange={(checked) => handleChange('white_cursor_status', checked ? 'active' : 'inactive')}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Form.Item style={{ marginBottom: 0, borderRadius: 8, border: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }}>
                                    <Row align="middle" justify="space-between">
                                        <Col>
                                            Big Black Cursor
                                        </Col>
                                        <Col>
                                            <Switch
                                                checked={formData?.feature_configs?.black_cursor_status === 'active'}
                                                onChange={(checked) => handleChange('black_cursor_status', checked ? 'active' : 'inactive')}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Form.Item style={{ marginBottom: 0, borderRadius: 8, border: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }}>
                                    <Row align="middle" justify="space-between">
                                        <Col>
                                            Keyboard Navigation
                                        </Col>
                                        <Col>
                                            <Switch
                                                checked={formData?.feature_configs?.keyboard_nav_status === 'active'}
                                                onChange={(checked) => handleChange('keyboard_nav_status', checked ? 'active' : 'inactive')}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Form.Item style={{ marginBottom: 0, borderRadius: 8, border: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }}>
                                    <Row align="middle" justify="space-between">
                                        <Col>
                                            Highlight Hover
                                        </Col>
                                        <Col>
                                            <Switch
                                                checked={formData?.feature_configs?.highlight_hover_status === 'active'}
                                                onChange={(checked) => handleChange('highlight_hover_status', checked ? 'active' : 'inactive')}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[16, 8]} style={{ marginBottom: '20px' }}>
                            <Col xs={24} sm={6}>
                                <Form.Item style={{ marginBottom: 0, borderRadius: 8, border: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }}>
                                    <Row align="middle" justify="space-between">
                                        <Col>
                                            Reading Mask
                                        </Col>
                                        <Col>
                                            <Switch
                                                checked={formData?.feature_configs?.reading_mask_status === 'active'}
                                                onChange={(checked) => handleChange('reading_mask_status', checked ? 'active' : 'inactive')}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Form.Item style={{ marginBottom: 0, borderRadius: 8, border: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }}>
                                    <Row align="middle" justify="space-between">
                                        <Col>
                                            Reading Guide
                                        </Col>
                                        <Col>
                                            <Switch
                                                checked={formData?.feature_configs?.reading_guide_status === 'active'}
                                                onChange={(checked) => handleChange('reading_guide_status', checked ? 'active' : 'inactive')}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item style={{ marginTop: '35px' }}>
                            <Button type="primary" htmlType="submit">
                                Submit Customizations
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Form>
                    <Form.Item style={{ marginTop: '35px' }}>
                        <Button type="primary" onClick={handleReset}>
                            Reset Customization
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </>
    )
}

