import { Authenticated, Refine } from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";
import { useNotificationProvider, ErrorComponent } from "@refinedev/antd";
import "@refinedev/antd/dist/reset.css";
import routerBindings,
{ CatchAllNavigate, DocumentTitleHandler, NavigateToResource, UnsavedChangesNotifier } from "@refinedev/react-router-v6";
import dataProvider from "@refinedev/simple-rest";
import { resources } from "config/resources";
import { App as AntdApp, ConfigProvider } from "antd";
import { ForgotPassword } from "pages/forgotPassword";
import { OtpVerificationForm } from "pages/otp";
import { Login } from "pages/login";
import { Register } from "pages/register";
import { UpdatePassword } from "pages/updatePassword"
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { authProvider } from "./authProvider";
import { Home } from './pages/dashboard'
import { MySites } from "pages/my-sites";
import { AddSite } from "pages/add-site";
import { Installation } from "pages/installation";
import { Widget } from "pages/widget"
import { EditProfile } from "pages/edit-profile";
import { Subscriptions } from "pages/subscriptions";
import { ColorModeContextProvider } from "./contexts/color-mode";
import { Layout } from "components";
import config from "config/config";
const API_BASE_URL = config.API_BASE_URL;

function App() {
  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: '#018e86',
                colorPrimaryText: '#444444',
                fontFamily: 'Segoe UI, Roboto, Helvetica Neue , Arial, Noto Sans, sans-serif'
              }
            }}
          >
            <AntdApp>
              <Refine
                dataProvider={dataProvider(`${API_BASE_URL}/user/api/v1/`)}
                notificationProvider={useNotificationProvider}
                routerProvider={routerBindings}
                authProvider={authProvider}
                resources={resources}
                options={{
                  syncWithLocation: true,
                  warnWhenUnsavedChanges: true,
                  useNewQueryKeys: true,
                  projectId: "rZa0OW-rM25Se-jjk9Ga",
                }}
              >
                <Routes>
                  <Route
                    element={
                      <Authenticated
                        key="authenticated-layout"
                        fallback={
                          <CatchAllNavigate to="/login" />
                        }
                      >
                        <Layout>
                          <Outlet />
                        </Layout>
                      </Authenticated>
                    }
                  >
                    <Route
                      index
                      element={<Home />}
                    />
                    <Route
                      path="/customize-widget"
                      element={<Widget />}>
                    </Route>
                    <Route
                      path="/my-sites"
                      element={<MySites />}>
                    </Route>
                    <Route
                      path="/add-site"
                      element={<AddSite />}>
                    </Route>
                    <Route
                      path='/edit-profile'
                      element={<EditProfile />}>
                    </Route>
                    <Route
                      path='/subscriptions'
                      element={<Subscriptions />}>
                    </Route>
                    <Route
                      path='/install-widget'
                      element={<Installation />}
                    >
                    </Route>
                    <Route
                      path="*"
                      element={<ErrorComponent />}
                    />
                  </Route>
                  <Route
                    element={
                      <Authenticated
                        key="authenticated-auth"
                        fallback={<Outlet />}
                      >
                        <NavigateToResource resource="dashboard" />
                      </Authenticated>
                    }
                  >
                    <Route
                      path="/login"
                      element={<Login />}
                    />
                    <Route
                      path="/register"
                      element={<Register />}
                    />
                    <Route
                      path="/forgot-password"
                      element={<ForgotPassword />}
                    />
                    <Route
                      path="/update-password"
                      element={<UpdatePassword />}
                    />
                    <Route
                      path="/verify-otp"
                      element={<OtpVerificationForm />}
                    />
                  </Route>
                </Routes>
                <RefineKbar />
                <UnsavedChangesNotifier />
                <DocumentTitleHandler />
              </Refine>
            </AntdApp>
          </ConfigProvider>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;
