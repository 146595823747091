import React, { useEffect } from 'react';
import { GoogleOutlined } from "@ant-design/icons";
import wafy from '../../assets/webaccessify-logo.png'
import {
    RegisterPageProps,
    RegisterFormTypes,
    useRouterType,
    useLink,
    useActiveAuthProvider,
} from "@refinedev/core";
import {
    Row,
    Col,
    Layout,
    Card,
    Typography,
    Form,
    Input,
    Button,
    CardProps,
    LayoutProps,
    Divider,
    FormProps,
    theme,
} from "antd";
import { useTranslate, useRouterContext, useRegister } from "@refinedev/core";

import {
    bodyStyles,
    containerStyles,
    headStyles,
    layoutStyles,
    titleStyles,
} from "styles";

const { Text, Title } = Typography;
const { useToken } = theme;

const nameRegex = /^[A-Za-z\s]+$/; // Only alphabets and spaces
//const passwordRegex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/;
//const websiteRegex = /^[a-zA-Z0-9]+$/; // Alphanumeric

type RegisterProps = RegisterPageProps<LayoutProps, CardProps, FormProps>;

export const Register: React.FC<RegisterProps> = ({
    providers,
    loginLink,
    wrapperProps,
    contentProps,
    renderContent,
    formProps,
    title,
    hideForm,
}) => {
    const { token } = useToken();
    const [form] = Form.useForm<RegisterFormTypes>();
    const translate = useTranslate();
    const routerType = useRouterType();
    const Link = useLink();
    const { Link: LegacyLink } = useRouterContext();

    const ActiveLink = routerType === "legacy" ? LegacyLink : Link;

    const authProvider = useActiveAuthProvider();
    const { mutate: register, isLoading } = useRegister<RegisterFormTypes>({
        v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
    });

    useEffect(() => {
        document.title = "Sign Up | WebAccessify";
    }, []);

    const PageTitle = title === false ? null : (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "32px",
                fontSize: "20px",
            }}
        >
            <img
                src={wafy}
                alt="WebAccessify Logo"
                style={{ height: '40px', width: '40px', marginRight: '8px' }}
            />
            <span style={{ ...titleStyles }}>
                WebAccessify
            </span>
        </div>
    );

    const CardTitle = (
        <Title
            level={3}
            style={{
                ...titleStyles,
            }}
        >
            {translate("pages.login.title", "Sign up for your account")}
        </Title>
    );

    // const renderProviders = () => {
    //     const socialProviders = [
    //         {
    //             name: "google",
    //             label: "Sign in with Google",
    //             icon: (
    //                 <GoogleOutlined
    //                     style={{
    //                         fontSize: 24,
    //                         lineHeight: 0,
    //                         color: token.colorPrimary,
    //                     }}
    //                 />
    //             ),
    //         },
    //     ];

    //     const allProviders = [...socialProviders, ...(providers || [])];

    //     if (allProviders.length > 0) {
    //         return (
    //             <>
    //                 {!hideForm && (
    //                     <Divider>
    //                         <Text
    //                             style={{
    //                                 color: token.colorTextLabel,
    //                             }}
    //                         >
    //                             {translate("pages.login.divider", "or")}
    //                         </Text>
    //                     </Divider>
    //                 )}
    //                 {allProviders.map((provider) => (
    //                     <Button
    //                         key={provider.name}
    //                         type="default"
    //                         size="middle"
    //                         block
    //                         icon={provider.icon}
    //                         style={{
    //                             display: "flex",
    //                             justifyContent: "center",
    //                             alignItems: "center",
    //                             width: "100%",
    //                             marginBottom: "8px",
    //                         }}
    //                         onClick={() =>
    //                             register({
    //                                 providerName: provider.name,
    //                             })
    //                         }
    //                     >
    //                         {provider.label}
    //                     </Button>
    //                 ))}

    //             </>
    //         );
    //     }

    //     return null;
    // };

    const CardContent = (
        <Card
            title={CardTitle}
            headStyle={headStyles}
            bodyStyle={bodyStyles}
            style={{
                ...containerStyles,
                backgroundColor: token.colorBgElevated,
            }}
            {...(contentProps ?? {})}
        >
            {!hideForm && (
                <Form<RegisterFormTypes>
                    layout="vertical"
                    form={form}
                    onFinish={(values) => register(values)}
                    requiredMark={false}
                    initialValues={{
                        remember: false,
                    }}
                    {...formProps}
                >
                    <Form.Item
                        name="name"
                        label={translate("pages.login.fields.name", "Full Name")}
                        rules={[
                            { required: true, message: 'Please enter your name' },
                            { pattern: nameRegex, message: 'Name must contain only alphabets' },
                        ]}
                    >
                        <Input size="middle" />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label={translate("pages.login.fields.email", "Email")}
                        rules={[
                            { required: true },
                            {
                                type: "email",
                                message: translate(
                                    "pages.login.errors.validEmail",
                                    "Invalid email address",
                                ),
                            },
                        ]}
                    >
                        <Input
                            size="middle" // Set input size to middle
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label={translate("pages.login.fields.password", "Password")}
                        rules={[
                            { required: true, message: 'Please enter your password' },
                            { min: 8, message: 'Password must be at least 8 characters long' },
                        ]}
                    >
                        <Input.Password size="middle" autoComplete="current-password" />
                    </Form.Item>
                    <Form.Item
                        name="website"
                        label={translate("pages.login.fields.website", "Website")}
                        rules={[
                            { required: true, message: 'Please enter your website domain!' },
                            {
                                pattern: /^(?!-)[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/,
                                message: 'Please enter a valid domain (e.g., example.com or sub.example.com)',
                            },
                        ]}
                    >
                        <Input size="middle" placeholder="example.com" />
                    </Form.Item>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "24px",
                        }}
                    >
                        {loginLink ?? (
                            <Text
                                style={{
                                    fontSize: 12,
                                    marginLeft: "auto",
                                }}
                            >
                                {translate(
                                    "pages.login.buttons.haveAccount",
                                    "Have an account?",
                                )}{" "}
                                <ActiveLink
                                    style={{
                                        fontWeight: "bold",
                                        color: token.colorPrimary,
                                    }}
                                    to="/login"
                                >
                                    {translate("pages.login.signin", "Sign in")}
                                </ActiveLink>
                            </Text>
                        )}
                    </div>
                    <Form.Item
                        style={{
                            marginBottom: 0,
                        }}
                    >
                        <Button
                            type="primary"
                            size="middle"
                            htmlType="submit"
                            loading={isLoading}
                            style={{ backgroundColor: token.colorPrimary }}
                            block
                        >
                            {translate(
                                "pages.register.buttons.submit",
                                "Sign up",
                            )}
                        </Button>
                    </Form.Item>
                </Form>
            )}

            {hideForm && loginLink !== false && (
                <div
                    style={{
                        marginTop: hideForm ? 16 : 8,
                    }}
                >
                    <Text
                        style={{
                            fontSize: 12,
                        }}
                    >
                        {translate(
                            "pages.login.buttons.haveAccount",
                            "Have an account?",
                        )}{" "}
                        <ActiveLink
                            style={{
                                fontWeight: "bold",
                                color: token.colorPrimaryTextHover,
                            }}
                            to="/login"
                        >
                            {translate("pages.login.signin", "Sign in")}
                        </ActiveLink>
                    </Text>
                </div>
            )}
            {/* {renderProviders()} */}
        </Card>
    );

    return (
        <Layout style={layoutStyles} {...(wrapperProps ?? {})}>
            <Row
                justify="center"
                align={hideForm ? "top" : "middle"}
                style={{
                    padding: "8px 0", // Reduce padding to decrease margin
                    minHeight: "100vh",
                    paddingTop: hideForm ? "10vh" : "8px",
                }}
            >
                <Col xs={22}>
                    {renderContent ? (
                        renderContent(CardContent, PageTitle)
                    ) : (
                        <>
                            {PageTitle}
                            {CardContent}
                        </>
                    )}
                </Col>
            </Row>
        </Layout>
    );
};
