import type { IResourceItem } from "@refinedev/core";

import {
    DashboardOutlined,
    GlobalOutlined,
    DesktopOutlined,
    WindowsOutlined,
    UserAddOutlined,
    CalendarOutlined
} from "@ant-design/icons";

export const resources: IResourceItem[] = [
    {
        name: "dashboard",
        options: { label: "Dashboard" },
        list: '/',
        icon: <DashboardOutlined />,
      },
      {
        name: "my_sites",
        options: { label: "My Websites" },
        list: '/my-sites',
        create: '/add-site',
        icon: <GlobalOutlined />,
      },
      {
        name: "Installation",
        options: { label: "Installation" },
        list: '/install-widget',
        icon: <DesktopOutlined />,
      },
      {
        name: "customize_widget",
        options: { label: "Widget" },
        list: '/customize-widget',
        icon: <WindowsOutlined />,
      },
      {
        name: "subscriptions",
        options: { label: "Subscriptions" },
        list: '/subscriptions',
        icon: <CalendarOutlined />,
      },
      {
        name: "edit_profile",
        options: { label: "Edit Profile" },
        list: '/edit-profile',
        icon: <UserAddOutlined />,
      },
];
