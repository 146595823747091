import { AuthBindings } from "@refinedev/core";
import axios from "axios";
import { message } from "antd";
import { log } from "console";
import config from "config/config";
export const TOKEN_KEY = "wafy-access-token";

const API_BASE_URL = config.API_BASE_URL;

export const axiosInstance = axios.create();
axiosInstance.interceptors.request.use((request) => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (token) {
    request.headers["Authorization"] = `Bearer ${token}`;
  }

  return request;
});

export const authProvider: AuthBindings = {
  login: async ({ email, providerName, password }) => {
    if (providerName === "google") {
      window.location.href = "https://accounts.google.com/o/oauth2/v2/auth";
      return {
        success: true,
      };
    } else {
      const lowerCaseEmail = email.toLowerCase();
      const response = await fetch(`${API_BASE_URL}/user/api/v1/signin`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: lowerCaseEmail, password })
      });

      if (response.status === 200) {
        const { token } = await response.json();
        localStorage.setItem(TOKEN_KEY, token);
        return {
          success: true,
          redirectTo: '/'
        }
      } else if (response.status === 401) {
        return {
          success: false,
          error: {
            message: "LoginError",
            name: "Invalid username or password",
          },
        };
      } else if (response.status === 404) {
        return {
          success: false,
          error: {
            message: "LoginError",
            name: "User not found",
          },
        };
      }
    }

    return {
      success: false,
      error: {
        message: "LoginError",
        name: "Login failed due to internal server error",
      },
    };
  },
  register: async ({ name, email, password, website }) => {
    const lowerCaseEmail = email.toLowerCase();
    const lowerCaseWebsite = website.toLowerCase();
    const response = await fetch(`${API_BASE_URL}/user/api/v1/signup`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ name, email: lowerCaseEmail, password, website: lowerCaseWebsite })
    });

    if (response.status === 201) {
      const data = await response.json();
      message.success('OTP has been sent to your Email Address');
      localStorage.setItem('userEmail', lowerCaseEmail);
      localStorage.setItem("otp_origin", "signup");
      return {
        success: true,
        redirectTo: '/verify-otp',
        ref: 'signup'
      }
    } else if (response.status === 400) {
      return {
        success: false,
        error: {
          message: "Register failed",
          name: "User already exists"
        }
      }
    }

    return {
      success: false,
      error: {
        message: "Register failed",
        name: "User registration failed due to internal server error",
      },
    }
  },
  logout: async () => {
    localStorage.removeItem(TOKEN_KEY);
    return {
      success: true,
      redirectTo: "/login",
    };
  },
  check: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      return {
        authenticated: true,
      };
    }

    return {
      authenticated: false,
      redirectTo: "/login",
    };
  },
  getPermissions: async () => {},
  getIdentity: async () => {
    try {
      const response = await axiosInstance.get(`${API_BASE_URL}/user/api/v1/userdata`);
      if (response.status === 200) {
        return response.data;
      } else if (response.status === 401 || response.status === 403) {
        localStorage.removeItem(TOKEN_KEY);
      } else if (response.status === 500) {
        localStorage.removeItem(TOKEN_KEY);
      } else {
        localStorage.removeItem(TOKEN_KEY);
      }
    } catch (error) {
      localStorage.removeItem(TOKEN_KEY);
      window.location.href = '/login';
    }
  },
  onError: async (error) => {
    if (error.status === 401 || error.status === 403) {
      return {
        logout: true,
        redirectTo: "/login",
        error,
      };
    }

    return {};
  },
};
