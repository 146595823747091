import React, {useEffect} from 'react';
import { Typography, Form, Input, Button, Row, Col, message } from "antd";
import { UserOutlined, LockOutlined, MailOutlined } from "@ant-design/icons";
import config from "config/config";
const { Title } = Typography;
const API_BASE_URL = config.API_BASE_URL;


export const EditProfile = () => {
    useEffect(() => {
        document.title = "Edit Profile | WebAccessify";
    }, []);

    const handlePasswordUpdate = async (values: any) => {
        try {
            const token = localStorage.getItem("wafy-access-token");
            const { oldPassword, newPassword, confirmPassword } = values;
            const response = await fetch(
                `${API_BASE_URL}/user/api/v1/updateProfilePassword`,
                {
                    method: 'PATCH',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ oldPassword, newPassword, confirmPassword })
                }
            );

            const data = await response.json();
            if (response.ok) {
                message.success(data.message);
            } else {
                message.error(data.message);
            }

        } catch (error) {
            message.error('Error occurred while updating user password');
        }
    }

    const handleEmailUpdate = async (values: any) => {
        try {
            const token = localStorage.getItem("wafy-access-token");
            const { newEmail, password } = values;
            const lowerCaseEmail = newEmail.toLowerCase();
            const response = await fetch(
                `${API_BASE_URL}/user/api/v1/updateEmail`,
                {
                    method: 'PATCH',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ newEmail: lowerCaseEmail, password })
                }
            );

            const data = await response.json();
            if (response.ok) {
                message.success(data.message);
            } else {
                message.error(data.message);
            }

        } catch (error) {
            message.error('Error occurred while updating email address');
        }
    }

    return (
        <div>
            <Title level={3}>Change Old Password</Title>
            <div style={{ marginTop: '20px', marginBottom: '20px', padding: '20px', borderRadius: '15px', backgroundColor: '#fcfcfc' }}>
                <Form layout="vertical" onFinish={handlePasswordUpdate}>
                    <Row gutter={[16, 8]}>
                        <Col xs={24} lg={8}>
                            <Form.Item
                                label="Old Password"
                                name="oldPassword"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter your old password!",
                                    },
                                ]}
                            >
                                <Input.Password prefix={<LockOutlined />} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={8}>
                            <Form.Item
                                label="New Password"
                                name="newPassword"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter your new password!",
                                    },
                                ]}
                            >
                                <Input.Password prefix={<LockOutlined />} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={8}>
                            <Form.Item
                                label="Confirm New Password"
                                name="confirmPassword"
                                dependencies={["newPassword"]}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please confirm your new password!",
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue("newPassword") === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    "The two passwords that you entered do not match!"
                                                )
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password prefix={<LockOutlined />} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Update Password
                            </Button>
                        </Form.Item>
                    </Row>
                </Form>
            </div>

            <Title level={3}>Change Email Address</Title>
            <div style={{ marginTop: '20px', padding: '20px', borderRadius: '15px', backgroundColor: '#fcfcfc' }}>
                <Form layout="vertical" onFinish={handleEmailUpdate}>
                    <Row gutter={[16, 8]}>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Enter Email Address"
                                name="newEmail"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter your email address!",
                                    },
                                ]}
                            >
                                <Input prefix={<MailOutlined />} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Enter Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter your password!",
                                    },
                                ]}
                            >
                                <Input.Password prefix={<LockOutlined />} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Update Email
                            </Button>
                        </Form.Item>
                    </Row>
                </Form>
            </div>
        </div>
    );
};
