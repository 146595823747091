import React, {useEffect} from 'react';
import wafy from '../../assets/webaccessify-logo.png'
import { useNavigate } from 'react-router-dom';
import {
  ForgotPasswordPageProps,
  ForgotPasswordFormTypes,
  useRouterType,
  useLink,
} from "@refinedev/core";
import {
  Row,
  Col,
  Layout,
  Card,
  Typography,
  Form,
  Input,
  Button,
  LayoutProps,
  CardProps,
  FormProps,
  theme,
  message,
} from "antd";
import {
  useTranslate,
  useRouterContext,
  useForgotPassword,
} from "@refinedev/core";

import {
  layoutStyles,
  containerStyles,
  titleStyles,
  headStyles,
  bodyStyles,
} from "styles";
import config from "config/config";

type ResetPassworProps = ForgotPasswordPageProps<
  LayoutProps,
  CardProps,
  FormProps
>;

const { Text, Title } = Typography;
const { useToken } = theme;
const API_BASE_URL = config.API_BASE_URL;

export const ForgotPassword: React.FC<ResetPassworProps> = ({
  loginLink,
  wrapperProps,
  contentProps,
  renderContent,
  formProps,
  title,
}) => {
  const navigate = useNavigate();
  const { token } = useToken();
  const [form] = Form.useForm<ForgotPasswordFormTypes>();
  const translate = useTranslate();
  const routerType = useRouterType();
  const Link = useLink();
  const { Link: LegacyLink } = useRouterContext();

  const ActiveLink = routerType === "legacy" ? LegacyLink : Link;

  useEffect(() => {
    document.title = "Forget Password | WebAccessify";
  }, []);

  const { mutate: forgotPassword, isLoading } =
    useForgotPassword<ForgotPasswordFormTypes>();

  const handleButtonClick = async () => {
    try {
      await form.validateFields();
      const { email } = form.getFieldsValue();
      const lowerCaseEmail = email?.toLowerCase();
      const response = await fetch(
        `${API_BASE_URL}/user/api/v1/updateOtp`,
        {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email: lowerCaseEmail })
        }
      );

      if (response.status === 200) {
        localStorage.setItem("userEmail", email ?? "")
        localStorage.setItem("otp_origin", "forgetPassword")
        message.success("OTP has been sent to your Email Address")
        navigate('/verify-otp');
      } else if (response.status === 404) {
        message.error('Email does not exist');
      }

    } catch (error) {
      message.error('Error occurred while checking email address');
    }
  };

  const PageTitle =
    title === false ? null : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "32px",
          fontSize: "20px",
        }}
      >
        <img
          src={wafy}
          alt="WebAccessify Logo"
          style={{ height: '40px', width: '40px', marginRight: '8px' }}
        />
        <span style={{ ...titleStyles }}>
          WebAccessify
        </span>
      </div>
    );

  const CardTitle = (
    <Title
      level={3}
      style={{
        ...titleStyles,
      }}
    >
      {translate("pages.forgotPassword.title", "Forgot your password?")}
    </Title>
  );

  const CardContent = (
    <Card
      title={CardTitle}
      headStyle={headStyles}
      bodyStyle={bodyStyles}
      style={{
        ...containerStyles,
        backgroundColor: token.colorBgElevated,
      }}
      {...(contentProps ?? {})}
    >
      <Form<ForgotPasswordFormTypes>
        layout="vertical"
        form={form}
        requiredMark={false}
        {...formProps}
      >
        <Form.Item
          name="email"
          label={translate(
            "pages.forgotPassword.fields.email",
            "Email",
          )}
          rules={[
            { required: true },
            {
              type: "email",
              message: translate(
                "pages.forgotPassword.errors.validEmail",
                "Invalid email address",
              ),
            },
          ]}
        >
          <Input
            type="email"
            size="middle"
          />
        </Form.Item>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {loginLink ?? (
            <Text
              style={{
                fontSize: 12,
                marginLeft: "auto",
              }}
            >
              {translate(
                "pages.register.buttons.haveAccount",
                "Have an account? ",
              )}{" "}
              <ActiveLink
                style={{
                  fontWeight: "bold",
                  color: token.colorPrimary,
                }}
                to="/login"
              >
                {translate("pages.login.signin", "Sign in")}
              </ActiveLink>
            </Text>
          )}
        </div>
        <Form.Item
          style={{
            marginTop: "24px",
            marginBottom: 0,
          }}
        >
          <Button
            type="primary"
            size="middle"
            htmlType="submit"
            loading={isLoading}
            style={{
              backgroundColor: token.colorPrimary
            }}
            block
            onClick={handleButtonClick}
          >
            {translate(
              "pages.forgotPassword.buttons.submit",
              "Submit",
            )}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );

  return (
    <Layout style={layoutStyles} {...(wrapperProps ?? {})}>
      <Row
        justify="center"
        align="middle"
        style={{
          padding: "16px 0",
          minHeight: "100dvh",
        }}
      >
        <Col xs={22}>
          {renderContent ? (
            renderContent(CardContent, PageTitle)
          ) : (
            <>
              {PageTitle}
              {CardContent}
            </>
          )}
        </Col>
      </Row>
    </Layout>
  );
}
