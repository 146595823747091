import React, { useEffect, useState } from "react";
import { Layout, Space, theme, Typography, Select, message } from "antd";
import { useGetIdentity } from "@refinedev/core";
import { Notifications } from "../notifications";
import { CurrentUser } from "../current-user";
import config from "config/config";

const { useToken } = theme;
const { Header: AntdHeader } = Layout;
const { Option } = Select;

const API_BASE_URL = config.API_BASE_URL;

export const Header: React.FC = () => {
    const { token } = useToken();
    const { data: user, refetch } = useGetIdentity<{
        name: string;
        user_websites: { url: string; selected: string }[];
    }>();

    const shouldRenderHeader = true;

    const [selectionBarWidth, setSelectionBarWidth] = useState<string>("440px");
    const [selectedWebsite, setSelectedWebsite] = useState<string>("");

    useEffect(() => {
        const updateSelectionBarWidth = () => {
            if (window.innerWidth <= 440) {
                setSelectionBarWidth("100%");
            } else {
                setSelectionBarWidth("25%");
            }
        };

        updateSelectionBarWidth();
        window.addEventListener("resize", updateSelectionBarWidth);
        return () => {
            window.removeEventListener("resize", updateSelectionBarWidth);
        };
    }, []);

    useEffect(() => {
        if (user) {
            const defaultWebsite = user.user_websites.find((website) => website.selected === 'true')?.url || "";
            setSelectedWebsite(defaultWebsite);
        }
    }, [user]);

    const headerStyles: React.CSSProperties = {
        backgroundColor: token.colorBgElevated,
        display: "flex",
        justifyContent: !!user ? "space-between" : "flex-end",
        alignItems: "center",
        padding: "0px 24px",
        height: "64px",
        position: "sticky",
        top: 0,
        zIndex: 999,
    };

    const handleWebsiteChange = async (value: string) => {
        try {
            const token = localStorage.getItem("wafy-access-token");
            const response = await fetch(
                `${API_BASE_URL}/user/api/v1/updateSelectedSite`,
                {
                    method: 'PATCH',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ selected: value.toLowerCase() })
                }
            );
            if (response.ok) {
                setSelectedWebsite(value);
                refetch(); // Refetch the identity data to update the state
            } else {
                message.error('Error occurred while updating selected website');
            }
        } catch (error) {
            message.error('Error occurred while updating selected website');
        }
    };

    // Generate options for Select based on user websites
    const options = user?.user_websites?.map((website) => ({
        url: website.url,
        selected: website.selected,
    })) || [];

    return shouldRenderHeader ? (
        <AntdHeader style={headerStyles}>
            {!!user && (
                <Select
                    variant="outlined"
                    style={{ width: selectionBarWidth, marginRight: "16px" }}
                    value={selectedWebsite}
                    onChange={handleWebsiteChange}
                >
                    {options.map((option, index) => (
                        <Option key={index} value={option.url}>
                            {option.url}
                        </Option>
                    ))}
                </Select>
            )}
            <Space align="center" size="middle">
                <Notifications />
                <CurrentUser />
            </Space>
        </AntdHeader>
    ) : null;
};
